import React, { useState, useEffect, useRef } from "react";
import "./ChatBot3.scss";

function ChatBot3(props) {
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    const element = messagesEndRef.current;
    element.scrollTop = element.scrollHeight;
  };

  //템플릿 리터럴 안에서 사용할 수 있도록 객체를 문자열화
  const mydata = JSON.stringify(props.myData);

  //대화 history상태 관리, 최초 입력값 세팅
  const [messages, setMessages] = useState([
    {
      role: "system",
      text: "당신은 숙련된 전문의이며, 상냥한 말투와 공감적 경청을 기본으로 상담에 응한다.",
    },
    {
      role: "user",
      text: `"제 건강정보는 ${mydata}이렇습니다. 이 내용을 바탕으로 상담해 주세요."`,
    },
  ]);

  //입력값 상태 관리
  const [inputText, setInputText] = useState("");
  //로딩완료 상태 관리
  const [isLoading, setIsLoading] = useState(false);

  //API호출에 사용할 Config 선언, 엔드포인트 및 전달할 API키
  const API_ENDPOINT = "https://api.openai.com/v1/chat/completions";
  const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

  useEffect(() => {
    // 스크롤 위치 조작
    scrollToBottom();
  }, [messages]);

  const sendMessage = async () => {
    if (inputText.trim() === "") return;

    try {
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "user", text: inputText },
      ]);
      
      setInputText("");
      setIsLoading(true);

      const history = messages.map((message) => ({
        role: message.role,
        content: message.text,
      }));

      const content =
        history
          .map((message) => `${message.role}: ${message.content}`)
          .join("\n") + `\nuser: ${inputText}`;

      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_KEY}`,
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          // model: "gpt-4",
          messages: [
            {
              role: "system",
              content:
                "당신은 전문 건강상담사입니다. 친절한 톤으로 응대해주세요. ",
            },
            { role: "user", content: content },
          ],
        }),
      });

      const data = await response.json();
      const reply = data.choices[0].message.content.replace("bot:", "");

      setMessages((prevMessages) => [
        ...prevMessages,
        // { role: "user", text: inputText },
        { role: "bot", text: reply },
      ]);
      
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
      
    }
  };

  useEffect(() => {
    console.log(messages);
    const handleNewMessage = async () => {
      try {
        setIsLoading(true);

        const history = messages.map((message) => ({
          role: message.role,
          content: message.text,
        }));

        const content = history
          .map((message) => `${message.role}: ${message.content}`)
          .join("\n");

        const response = await fetch(API_ENDPOINT, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`,
          },
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            // model: "gpt-4",

            messages: [
              {
                role: "system",
                content:
                  "당신은 전문 건강상담사입니다. 친절한 톤으로 응대해주세요. ",
              },
              { role: "user", content: content },
            ],
          }),
        });

        const data = await response.json();
        const reply = data.choices[0].message.content.replace("bot:", "");
        // {console.log(reply)}
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: "bot", text: reply },
        ]);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (messages[messages.length - 1].role === "user") {
      handleNewMessage();
    }
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  return (
    <div className="chatbot-container">
      <div className="message-container" ref={messagesEndRef}>
        {messages.map(
          (message, index) =>
            message.role !== "system" &&
            index !== 1 && (
              <div key={index} className={`message ${message.role}`}>
                {message.text}
              </div>
            )
        )}

        {isLoading && <div className="message bot">답변 작성 중... 서버 상태에 따라 최대 1분이 걸릴 수 있습니다.</div>}
      </div>
      <div className="input-container">
        <input
          type="text"
          // value={isLoading === false ? inputText:''}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="메시지를 입력하세요..."
          onKeyUp={handleKeyPress}
        />
        <button onClick={sendMessage}>전송</button>
      </div>
    </div>
  );
}

export default ChatBot3;
