import { Link } from "react-router-dom";
import "./Main.scss";
import { useState } from "react";

export const Main = () => {
  const [clickedTime, setClickedTime] = useState(null);
  const getData = () => {};
  const handleClick = () => {
    const currentDateTime = new Date();
    const formattedDateTime = `${currentDateTime.getFullYear()}-${(
      "0" +
      (currentDateTime.getMonth() + 1)
    ).slice(-2)}-${("0" + currentDateTime.getDate()).slice(-2)} ${(
      "0" + currentDateTime.getHours()
    ).slice(-2)}:${("0" + currentDateTime.getMinutes()).slice(-2)}`;
    setClickedTime(formattedDateTime);
  };

  return (
    <div className="cont">
      <div className="box">
        <div className="loadDataBtn" onClick={handleClick}>
          {/* <Link to="/mydata">검진정보 불러오기</Link> */}
          검진정보 불러오기
        </div>
        {/* <div className="loadDataBtn" onClick={handleClick}>기능테스트용 Logging 버튼</div> */}{" "}
        {clickedTime ? (
          <>
            <div className="lastLoaded">{`마지막 데이터 불러온 시간: ${clickedTime}`}</div>
            <div className="moveToMydata">
              <Link to="/mydata">내 검진정보 확인</Link>
            </div>
            <div className="loadDataBtn">
              {" "}
              <Link to="/counseling">챗봇 상담하기</Link>{" "}
            </div>
          </>
        ) : (
          ""
        )}{" "}
      </div>
    </div>
  );
};
