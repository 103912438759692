import { Link } from "react-router-dom";
import { useState } from "react";
import "./Mydata.scss";

const Mydata = (props) => {
  //값 초기화를 위한 init데이터 설정
  const initMyData = props.initMyData;
  //화면에 표시해 주기 위한 입력값 상태 관리
  const [updatedData, setUpdatedData] = useState([...props.myData]);

  // 수치값변경에 따른 입력값 변경 상태 관리
  const handleValueChange = (index, event) => {
    const dataCopy = [...updatedData];
    const updatedObj = { ...dataCopy[index] };
    updatedObj[Object.keys(updatedObj)[0]] = event.target.value;
    dataCopy[index] = updatedObj;
    setUpdatedData(dataCopy);
  };

  //변경된(업데이트된) 입력값을 props로 전달받은 세터를 이용하여 업데이트
  const handleApplyChanges = () => {
    props.setMyData(updatedData);
  };

  //props로 전달받은 최초 값으로 값 리셋
  const handleReset = () => {
    setUpdatedData(initMyData);
    props.setMyData(initMyData);
  };

  return (
    <div className="container">
      <div className="column-title">
        <div
          className="box-title"
          onClick={() => {
            console.log(props.myData);
          }}
        >
          검진정보 불러오기 결과 화면
        </div>
      </div>
      <div className="column-datas">
        <div className="box">
          <div className="row">
            <div className="sub-box1">
              {props.myData.map((obj, index) => (
                <div className="data-key" key={index}>
                  {Object.keys(obj)[0]}
                </div>
              ))}
            </div>
            <div className="sub-box2">
              {updatedData.map((obj, index) => (
                <input
                  className="data-value"
                  type="text"
                  value={Object.values(obj)[0]}
                  onChange={(event) => handleValueChange(index, event)}
                  key={index}
                />
              ))}
            </div>
            <div className="sub-box3">
              {props.unitData.map((obj, index) => (
                <div className="data-unit" key={index}>
                  {Object.values(obj)[0]}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="column-buttons">
        <button onClick={handleApplyChanges}>적용</button>
        <button onClick={handleReset}>초기화</button>
      </div>
      <div className="column-chatbot">
        <div className="box-chatbot">
          <Link to="/counseling">챗봇 상담 버튼</Link>
        </div>
      </div>
    </div>
  );
};

export default Mydata;
