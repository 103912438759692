import {useState} from 'react'
import { Route, Routes } from 'react-router-dom';
import { Home } from "./pages/Home";
import { Main } from './pages/Main';
import Mydata from './pages/Mydata';
import Counseling from './pages/Counseling';


function App() {


  //드릴링을 위한 상태관리
  const [myData , setMyData] = useState([
    {
      "신장": "172"
    },
    {
      "체중": "78.2"
    },
    {
      "허리둘레": "88"
    },
    {
      "교정 시력 좌": "0.3"
    },
    {
      "교정 시력 우": "0.6"
    },
    {
      "청력 좌": "정상"
    },
    {
      "청력 우": "정상"
    },
    {
      "혈압 최고": "121"
    },
    {
      "혈압 최저": "78"
    },
    {
      "혈색소": "15.9"
    },
    {
      "공복혈당": "99"
    },
    {
      "혈청 크레아티닌": "1"
    },
    {
      "신사구체여과율(e-GFR)": "83"
    },
    {
      "AST(SGOT)": "36"
    },
    {
      "ALT(SGPT)": "82"
    },
    {
      "감마지티피(γ-GTP)": "85"
    },
    {
      "요단백": "정상"
    },
    {
      "흉부촬영": "정상"
    }
  ])

  const initMyData=[
    {
      "신장": "172"
    },
    {
      "체중": "78.2"
    },
    {
      "허리둘레": "88"
    },
    {
      "교정 시력 좌": "0.3"
    },
    {
      "교정 시력 우": "0.6"
    },
    {
      "청력 좌": "정상"
    },
    {
      "청력 우": "정상"
    },
    {
      "혈압 최고": "121"
    },
    {
      "혈압 최저": "78"
    },
    {
      "혈색소": "15.9"
    },
    {
      "공복혈당": "99"
    },
    {
      "혈청 크레아티닌": "1"
    },
    {
      "신사구체여과율(e-GFR)": "83"
    },
    {
      "AST(SGOT)": "36"
    },
    {
      "ALT(SGPT)": "82"
    },
    {
      "감마지티피(γ-GTP)": "85"
    },
    {
      "요단백": "정상"
    },
    {
      "흉부촬영": "정상"
    }
  ]

  const unitData = 
  [
    { "신장": "cm" },
    { "체중": "kg" },
    { "허리둘레": "cm" },
    { "교정 시력 좌": " " },
    { "교정 시력 우": " " },
    { "청력 좌": " " },
    { "청력 우": " " },
    { "혈압 최고": "mmHg" },
    { "혈압 최저": "mmHg" },
    { "혈색소": "g/dL" },
    { "공복혈당": "mg/dL" },
    { "혈청 크레아티닌": "mg/dL" },
    { "신사구체여과율(e-GFR)": "mL/min" },
    { "AST(SGOT)": "IU/L" },
    { "ALT(SGPT)": "IU/L" },
    { "감마지티피(γ-GTP)": "IU/L" },
    { "요단백": " " },
    { "흉부촬영": " " }
  ]

  return (
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/main" element={<Main/>}/>
      <Route path="/mydata" element={<Mydata myData={myData} unitData={unitData} setMyData={setMyData} initMyData={initMyData}/> }/>
      <Route path="/counseling" element={<Counseling myData={myData}/>}/>
    </Routes>
  );
}

export default App;
