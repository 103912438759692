import LoginForm from "../components/LoginForm";
import "./Home.scss";

export const Home = () => {
  return (
    <div className="cont">
      <div className="login">
        <LoginForm />
      </div>
    </div>
  );
};
