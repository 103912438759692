// import ChatBot from '../components/ChatBot'

import ChatApp from '../components/ChatApp'
import ChatBot2 from '../components/ChatBot2'
import ChatBot3 from '../components/ChatBot3'
import './Counseling.scss'

const Counseling = (props)=>{

    return(
        <div className='container'>
            <div className='controlBox'>
                <div className='titleHeader'>'이 상담은 인공지능 상담으로, 부정확한 정보를 제공할 수 있습니다. 확실한 내용은 의사에게 상담하십시오.' </div>
                <div className='titleMain'>챗봇상담</div>
                <div className='main'>
                {/* <ChatBot/> */}
                {/* <ChatApp/>                 */}
                {/* <ChatBot2/> */}
                <ChatBot3 myData={props.myData}/>
                </div>
                {/* <div className='userPrompt'>
                    유저 입력 및 전송공간
                    </div> */}

            </div>

        </div>
    )
}

export default Counseling