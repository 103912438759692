import React, { useState } from "react";
import styles from "./LoginForm.module.scss";
import { useNavigate } from 'react-router-dom';

function LoginForm() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");


  

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted:", username, password);
    // eslint-disable-next-line no-lone-blocks
    {
      username === "ajou" && password === "ajou"
        ? 
        // console.log('Login Success')
        navigate('/main')
        : console.log("need check your ID and PW");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.loginForm}>
        <div className={styles.formGroup}>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <button type="submit">Log In</button>
      </div>
    </form>
  );
}

export default LoginForm;
